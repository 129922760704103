<template>
  <div>
    <a-spin :spinning="loading">
      <div class="flex alcenter">
        <div class="a-c">
          <div class="ac-l">
            <img
              v-if="account.face != null"
              style="width: 60px; height: 60px; border-radius: 30px"
              :src="account.face"
            />
            <img
              v-else
              style="width: 60px; height: 60px; border-radius: 30px"
              src="../assets/image/woyaoyou_head_defult@2x.png"
            />
            <div class="aa ft14 cl-main ftw600">{{ account.username }}</div>
            <a-button class="bb" @click="showPwd = true" type="link"
              >修改密码</a-button
            >
          </div>
          <div class="ac-r">
            <a @click="addMendian"
              ><div class="tt">
                <img
                  src="../assets/image/jia.png"
                  width="18"
                  height="18"
                />创建商铺
              </div></a
            >
          </div>
        </div>
      </div>
      <!--<div class="mt40 flex alcenter">
        <span class="ft20 ftw600 cl-main">我的商铺({{ mendians.length }})</span>
        <span class="ft14 cl-notice ml10"
          >每个商铺为独立运营的，会员是不通用的。每个商铺可以邀请合伙人一起管理</span
        >
      </div>-->
      <div class="mt30 mendian-list">
        <div
          v-for="item in mendians"
          @click="manageAct(item)"
          :class="{ dis: item.shop == null }"
          class="mendian-item flex space"
        >
          <div>
            <div class="flex alcenter">
              <div class="ft16 ftw600 cl-main text-over4">
                {{ item.shop == null ? "该门店已注销" : item.shop.name }}
              </div>
              <div class="ft14 ftw400 cl-notice ml5 text-over4">
                ({{
                  item.shop == null
                    ? ""
                    : item.shop.application == null
                    ? ""
                    : item.shop.application.name
                }})
              </div>
            </div>
            <div class="mt12 ft12 cl-info">
              开通时间：{{ item.add_time_format }}
            </div>
            <div
              class="mt12 ft12 cl-info"
              v-if="
                item.shop.expire_time * 1000 > current_time.getTime() &&
                item.shop.type == 1
              "
            >
              店铺状态：<span style="color: red">试用中</span>
            </div>
            <div
              class="mt12 ft12 cl-info"
              v-if="
                item.shop.expire_time * 1000 > current_time.getTime() &&
                item.shop.type == 2
              "
            >
              店铺状态：<span style="color: blue">正式版</span>
            </div>
            <div
              class="mt12 ft12 cl-info"
              v-if="item.shop.expire_time * 1000 < current_time.getTime()"
            >
              店铺状态：<span style="color: grey">已过期</span>
            </div>
          </div>
          <div class="tag" v-if="item.role_id == 1">创始人</div>
          <div class="tag" v-if="item.role_id == 2">合伙人</div>
        </div>
      </div>
    </a-spin>

    <a-modal v-model="showPwd" title="设置密码" on-ok="handleOk">
      <template slot="footer">
        <a-button key="back" @click="handlePwdCancel"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="pwdloading"
          @click="handlePwdOk"
        >
          确认
        </a-button>
      </template>
      <a-form-model
        :model="pwd"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="输入密码">
          <a-input type="password" v-model="pwd.pwd" />
        </a-form-model-item>
        <a-form-model-item label="再次确认">
          <a-input type="password" v-model="pwd.repwd" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      loading: false,
      showPwd: false,
      pwd: {
        pwd: "",
        repwd: "",
      },
      pwdloading: false,
      account: {
        mobile: "",
        username: "",
        face: null,
      },
      mendians: [],
      current_time: new Date(),
    };
  },

  created() {
    this.getLists();
  },
  methods: {
    handlePwdCancel() {
      this.showPwd = false;
    },
    handlePwdOk() {
      if (this.pwdloading == true) return;
      this.pwdloading = true;
      this.$http
        .api("platform/account/editPwd", {
          pwd: this.pwd.pwd,
          repwd: this.pwd.repwd,
        })
        .then((res) => {
          this.pwdloading = false;
          this.$message.success("密码设置成功");
          this.showPwd = false;
        })
        .catch((res) => {
          this.pwdloading = false;
        });
    },
    getLists() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/account/home")
        .then((res) => {
          this.account = res.account;
          this.mendians = res.mendian;
          if (this.account.password == null || this.account.password == "") {
            this.showPwd = true;
          }
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    manageAct(item) {
      if (item.shop == null) {
        this.$message.error("该门店已注销");
        return false;
      }
      let base_url = item.shop.application.development_address;
      window.open(
        base_url + "auth?shop_token=" + encodeURIComponent(item.shop_token)
      );
    },
    handleOk() {
      this.showForm = false;
    },
    handleCancel() {
      this.showForm = false;
    },
    addMendian() {
      // this.showForm = true;
      this.$router.push("/mendian/create");
    },
  },
};
</script>

<style>
.mendian-list {
  display: grid;
  grid-template-columns: repeat(3, 380px);
  grid-template-rows: repeat(3, 100px);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}
.mendian-item {
  width: 380px;
  height: 120px;
  background: #ffffff;
  border-radius: 8px;
  border-left: 8px solid #ebedf5;
  padding: 24px 16px;
  cursor: pointer;
  transition: all 0.3s;
}

.mendian-item.dis {
  opacity: 0.5;
}
.mendian-item .tag {
  width: 52px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #4772ff;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  color: #4772ff;
}
.mendian-item:hover {
  transform: translateY(-10px);
}
.mendian-item.add {
  border: 1px dashed #4772ff;
}

/*用户*/
.a-c {
  width: 100%;
  display: flex;
}
.a-c .ac-l {
  width: 80%;
  display: flex;
  align-items: center;
}
.a-c .ac-l img {
  border-radius: 200px;
}
.a-c .ac-l .aa {
  margin: 0px 20px;
}
.a-c .ac-l .bb {
  color: #4772ff;
}
.a-c .ac-r {
  width: 20%;
}
.a-c .ac-r .tt {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4772ff;
  border: 1px dashed #4772ff;
  border-radius: 5px;
}
.a-c .ac-r .tt img {
  margin-right: 10px;
}
</style>